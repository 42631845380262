// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "foundation-sites"
import $ from 'jquery';
import select2 from 'select2';
import flatpickr from "flatpickr";
import 'select2/dist/css/select2.css';
import 'flatpickr/dist/flatpickr.css';
import "@fortawesome/fontawesome-free/js/all";
require("stylesheets/application")
import "controllers"

$(document).on('turbolinks:load', function() {
    $(document).foundation()
});

$(document).ready(function() {
    $("select.select2-input").on('select2:select', function() {
        let event = new Event('change'); // fire a native event
        this.dispatchEvent(event);
    });
});

$(document).on('turbolinks:load', function() {
    $('input#select-all').click(function() {
        var checkboxes = document.getElementsByName('shift_ids[]');
        for (var i = 0, n = checkboxes.length; i < n; i++) {
            checkboxes[i].checked = document.getElementById('select-all').checked;
        };
    });

    // https://select2.org/programmatic-control/add-select-clear-items
    // https://select2.org/data-sources/ajax


    // This block is to preserve previously selected consumer / employees
    var consumerData = presetUser('consumer-select-div');
    var employeeData = presetUser('employee-select-div');

    if (consumerData) {
        var newOption = new Option(consumerData.text, consumerData.id, false, false);
        $('#consumer-async').append(newOption).trigger('change');
    }

    if (employeeData) {
        var newOption = new Option(employeeData.text, employeeData.id, false, false);
        $('#employee-async').append(newOption).trigger('change');
    }

    $('#consumer-async').select2({
        ajax: {
            url: '/shifts/consumers/',
            dataType: 'json',
            data: function(params) {
                var query = {
                    fuzzy_name: params.term
                }
                return query
            },
            processResults: function(data) {
                var processed = []
                data.forEach((element, index) => {
                    processed.push({ text: `${element.data.firstName} ${element.data.lastName} (${element.data.internalNumber})`, id: element.data.id })
                })
                return {
                    results: processed
                }
            },
        },
        placeholder: I18n['consumer'],
        minimumInputLength: 3
    })

    $('#employee-async').select2({
        ajax: {
            url: '/shifts/employees/',
            dataType: 'json',
            data: function(params) {
                var element = document.getElementById('consumer-async');
                var query = {};
                if (element != null && element.value != null && element.value != '') {
                    debugger;
                    var query = {
                        fuzzy_name: params.term,
                        consumer_id: element.value
                    }
                } else {
                    var query = {
                        fuzzy_name: params.term
                    }
                }
                return query
            },
            processResults: function(data) {
                var processed = []
                data.forEach((element, index) => {
                    processed.push({ text: `${element.data.firstName} ${element.data.lastName} (${element.data.internalNumber})`, id: element.data.id })
                })
                return {
                    results: processed
                }
            },
        },
        placeholder: I18n['employee'],
        minimumInputLength: 3
    })

    $('a.form_error').on('click', function(){
        var input_field = this.id + ('_input')
        document.getElementById(input_field).focus();
    })

    $('a.form_error').on('keypress', function(e){
        if(e.keyCode == 13){
            var input_field = this.id + ('_input')
            document.getElementById(input_field).focus();
        }
        
    })

    $('.is-invalid-input, .select2-selection--single').on('click', function(){
        var parentElement = this.closest(".valid")
        if(parentElement.classList.contains('error-container')){
            parentElement.classList.remove('error-container')
            parentElement.getElementsByTagName('label')[0].classList.remove('is-invalid-label')
            this.classList.remove('is-invalid-input')
            parentElement.getElementsByClassName('hhax-form-error-text')[0].remove()
        }
    })

    $('input.radio_buttons').on('click', function() {
        let role_value = $(this).val()
        if(role_value.length){
            $('#submit-role-selection').attr('disabled', false)
        } else {
            $('#submit-role-selection').attr('disabled', true) 
        }
    });

    $('#add_more_entry').on('click', function() {
        const add_more_flag = document.querySelector('#batch_shift_entry_add_more_entry');
        add_more_flag.value = 'true';

        document.getElementsByClassName('edit_batch_shift_entry')[0].submit();
    });

    $('#consumer_fuzzy_name').on('keypress', function(e) {
        if(e.keyCode == 13){ // the enter key code
            e.preventDefault();
            $('#gatherer-button')[0].click();
        }
    });
    var toggle_input = document.getElementById("select-all")
    if(toggle_input !== null){
        toggle_input.addEventListener("focus", addToggleFocus, false);
        toggle_input.addEventListener("focusout", removeToggleFocus, false);
    }
});

function presetUser(div_id) {
    var userDiv = document.getElementById(div_id)

    if (userDiv != null) {
        var id = parseInt(userDiv.dataset.id);
        if (id) {
            return {
                id: id,
                text: userDiv.dataset.name
            };
        }
    }
}

function addToggleFocus() {
    var checkBox = document.getElementById("select-all");
    var element = document.getElementsByClassName("paddle-focus");
    if(document.activeElement == checkBox){
        element[0].classList.add("toggle-focus");
    }
}

function removeToggleFocus() {
    var element = document.getElementsByClassName("paddle-focus");
    element[0].classList.remove("toggle-focus");
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)