import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["consumerId", "employeeId", "consumerEnrollmentIdPlaceholder", "employeeIdPlaceholder", "additionalFieldsPlaceholder", "serviceCodePlaceholder"];

  beforeReflex (element, reflex) {
    super.beforeReflex(element, reflex)

    if (document.getElementById('searching-indicator')) {
       document.getElementById('searching-indicator').innerHTML = 'Searching...'
    }

    if (reflex === 'SharedShiftReflex#select_consumer_id') {
      if (this.hasEmployeeIdPlaceholderTarget) {
        this.employeeIdPlaceholderTarget.hidden = false
      }
    }

    if (reflex === 'SharedShiftReflex#select_consumer_enrollment_id') {
      if (this.hasServiceCodePlaceholderTarget) {
       this.serviceCodePlaceholderTarget.hidden = false
      }

      if (this.hasAdditionalFieldsPlaceholderTarget) {
        this.additionalFieldsPlaceholderTarget.hidden = false
      }
    }

    if (reflex === 'SharedShiftReflex#select_employee_id') {
      if (this.hasConsumerEnrollmentIdPlaceholderTarget) {
        this.consumerEnrollmentIdPlaceholderTarget.hidden = false
      }
    }
  }

  afterReflex (element, reflex) {
    super.afterReflex(element, reflex)

    if (reflex === 'SharedShiftReflex#select_consumer_id') {
      if (this.hasEmployeeIdPlaceholderTarget) {
        this.employeeIdPlaceholderTarget.hidden = true
      }
    }

    if (reflex === 'SharedShiftReflex#select_consumer_enrollment_id') {
      if (this.hasServiceCodePlaceholderTarget) {
       this.serviceCodePlaceholderTarget.hidden = true
      }

      if (this.hasAdditionalFieldsPlaceholderTarget) {
        this.additionalFieldsPlaceholderTarget.hidden = true
      }
    }

    if (reflex === 'SharedShiftReflex#select_employee_id') {
      if (this.hasConsumerEnrollmentIdPlaceholderTarget) {
        this.consumerEnrollmentIdPlaceholderTarget.hidden = true
      }
    }
  }

  get consumerIdVal() {
    if (this.hasConsumerIdTarget) {
      return parseInt(this.consumerIdTarget.value);
    } else {
      return null;
    }
  }

  get employeeIdVal() {
    if (this.hasEmployeeIdTarget) {
      return parseInt(this.employeeIdTarget.value);
    } else {
      return null;
    }
  }
}
